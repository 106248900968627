import Cookies from 'js-cookie';
// const cookies = require('js-cookie');


export default class CookiesController{

    constructor()
    {
        this.cookies = Cookies;
    }

    set cookies(npmModule)
    {
        this._cookies = npmModule;
    }

    get cookies()
    {
        return this._cookies;
    }


    getCookies(name)
    {
        return this.cookies.get(name)
    }

    getAllCookies()
    {
        return this.cookies.get();
    }

    setCookie(name,value,options={})
    {
        this.cookies.set(name,value,options)
    }
}
